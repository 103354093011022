import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import axios from "axios";
import { history } from "../../_helpers";
import Menu, { SubMenu, Item as MenuItem } from "rc-menu";
import SearchIcon from "../../Assets/images/svg/search-icon.svg";
import Logo from "../../Assets/images/svg/lamedia_logo.svg";
import DropDownIcon from "../../Assets/images/svg/drop-down-icon.svg";
import { authHeader } from "../../_helpers";
import { isMobile } from "react-device-detect";

import PropTypes from "prop-types";

// Translation Higher Order Component
import { setLanguage, translate } from "react-switch-lang";

const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");

// function handleClick(info) {
// //   // console.log(`clicked ${info.key}`);
//   // if(isMobile) {
//   //   let curent = this;
//   //   // console.log('info');
//   //   this.setState({
//   //     menuButtonClicked:false
//   //   });
//   // }
//   return true;
// }

function parentItems(data) {
  var items = data.map((parent) => {
    return bubu(parent);
  });
  return items;
}
function bubu(item) {
  let language = JSON.parse(localStorage.getItem("lang"));
  let languageCode = language.code;
  if (isMobile) {
    if (item.sub.length > 0) {
      return (
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <a href={`/${languageCode}/category/` + item.data.urlKey}>
                {item.data.title}
              </a>
            </span>
          }
          key={item.data._id}
        >
          {item.sub.map((m) => {
            return bubu(m);
          })}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem key={item.data._id}>
          <a href={`/${languageCode}/category/` + item.data.urlKey}>
            {item.data.title}
          </a>
        </MenuItem>
      );
    }
  } else {
    if (item.sub.length > 0) {
      return (
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <Link to={`/${languageCode}/category/` + item.data.urlKey}>
                {item.data.title}
              </Link>
            </span>
          }
          key={item.data._id}
        >
          {item.sub.map((m) => {
            return bubu(m);
          })}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem key={item.data._id}>
          <Link to={`/${languageCode}/category/` + item.data.urlKey}>
            {item.data.title}
          </Link>
        </MenuItem>
      );
    }
  }
}

function onOpenChange(value) {
  //  console.log('onOpenChange', value);
}

const customizeIndicator = <span>Add More Items</span>;

class CommonMenu extends React.Component {
  toggleOverflowedIndicator = () => {
    this.setState({
      overflowedIndicator:
        this.state.overflowedIndicator === undefined
          ? customizeIndicator
          : undefined,
    });
  };

  render() {
    const { triggerSubMenuAction, mode, data } = this.props;
    return (
      <nav>
        <Menu
          triggerSubMenuAction={triggerSubMenuAction}
          onOpenChange={onOpenChange}
          mode={mode}
        >
          {parentItems(data)}
        </Menu>
      </nav>
    );
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      menuItems: [],
      UsdToAmd: [],
      RubToAmd: [],
      EurToAmd: [],
      isLoggedIn: true,
      userToolTipClass: "",
      langToolTipClass: "",
      searchFieldErorrM: false,
      currency: {},
      serachedValue: "",
      isMobile: false,
      menuButtonClicked: false,
      searchHide: "show",
      time: new Date().getTime(),
    };
    this.userLogout = this.userLogout.bind(this);
    this.userToolTip = this.userToolTip.bind(this);
    this.langToolTip = this.langToolTip.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setLangWrapperRef = this.setLangWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handelMenuButtonClick = this.handelMenuButtonClick.bind(this);
    this.onSelectMenuLink = this.onSelectMenuLink.bind(this);
    this.hendelUrlSerachParhams = this.hendelUrlSerachParhams.bind(this);
    this.handleSetLanguage = this.handleSetLanguage.bind(this);
  }

  _isMounted = false;
  _isExchangeRates = false;

  handleChange(e) {
    if (e.target.value !== undefined) {
      this.setState({
        searchedValue: e.target.value,
        searchFieldErorrM: false,
      });
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      let language = JSON.parse(localStorage.getItem("lang"));
      let languageCode = language.code;
      if (event.target.value !== "" && event.target.value !== undefined) {
        history.push({
          pathname: "/" + languageCode + "/search",
          search: "?s=" + event.target.value.toLowerCase(),
          query: { s: event.target.value.toLowerCase() },
        });
      } else {
        this.setState({
          searchFieldErorrM: true,
        });
      }
    }
  };

  handleSearch(event) {
    if (
      this.state.searchedValue !== "" &&
      this.state.searchedValue !== undefined
    ) {
      let language = JSON.parse(localStorage.getItem("lang"));
      let languageCode = language.code;

      history.push({
        pathname: "/" + languageCode + "/search",
        search: "?s=" + this.state.searchedValue.toLowerCase(),
        query: { s: this.state.searchedValue.toLowerCase() },
      });
    } else {
      this.setState({
        searchFieldErorrM: true,
      });
    }
  }
  userLogout(e) {
    this.props.dispatch(userActions.logout());
    this.setState({
      isLoggedIn: false,
      userToolTipClass: "",
      menuButtonClicked: false,
    });
    window.location.reload();
  }
  userToolTip(e) {
    if (this.state.userToolTipClass === "") {
      if (this._isMounted) {
        this.setState({
          userToolTipClass: "show",
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          userToolTipClass: "",
        });
      }
    }
  }
  langToolTip(e) {
    let element = document.getElementsByClassName("selected-lang");
    if (this.state.langToolTipClass === "") {
      if (this._isMounted) {
        element[0].classList.add("open");
        this.setState({
          langToolTipClass: "show",
        });
      }
    } else {
      if (this._isMounted) {
        element[0].classList.remove("open");
        this.setState({
          langToolTipClass: "",
        });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);
    this._isMounted = false;
    this._isExchangeRates = false;
    // this.setState({
    //   menuButtonClicked:false
    // });
  }

  handelMenuButtonClick(e) {
    let { menuButtonClicked } = this.state;
    if (menuButtonClicked) {
      this.setState({
        menuButtonClicked: false,
      });
    } else {
      this.setState({
        menuButtonClicked: true,
      });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.className !== "full-name"
    ) {
      if (this._isMounted) {
        this.setState({
          userToolTipClass: "",
        });
      }
    }
  }
  setLangWrapperRef(node) {
    this.wrapperLangRef = node;
  }
  handleClickOutside2(event) {
    if (
      this.wrapperLangRef &&
      !this.wrapperLangRef.contains(event.target) &&
      event.target.className !== "selected-lang"
    ) {
      if (this._isMounted) {
        this.setState({
          langToolTipClass: "",
        });
      }
    }
  }
  onSelect = (key) => {
    if (this._isMounted) {
      this.setState({
        userToolTipClass: "",
        menuButtonClicked: false,
      });
    }
  };
  onSelectMenuLink = (key) => {
    this.setState({
      menuButtonClicked: false,
    });
  };

  checkUser() {
    axios
      .get(apiUrl + "/users/current", { headers: authHeader() })
      .then(function (response) {
        var userData = response.data;
        let curentUser = JSON.parse(localStorage.getItem("user"));
        let userToken = curentUser.token;
        // console.log(userData)

        userData.token = userToken;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(userData));
      });
  }

  componentDidMount() {
    let currentComponent = this;
    let windowUrl = window.location.pathname.split("/");
    const labgInfo = {
      code: windowUrl[1],
    };
    setLanguage(windowUrl[1]);
    localStorage.setItem("lang", JSON.stringify(labgInfo));
    // console.log(windowUrl)
    // console.log(this.props)
    // console.log(window.location.pathname.substring(1))
    // console.log(window.location.pathname.substring(3))

    if (localStorage.getItem("user")) {
      this.checkUser();
    }
    window.onscroll = function (ev) {
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );
      if (scrollTop === 0) {
        // console.log('show')
        currentComponent.setState({
          searchHide: "show",
        });
      } else {
        // console.log('hide')
        currentComponent.setState({
          searchHide: "hide",
        });
      }
    };
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside2);
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    //  console.log(languageCode)
    axios
      .get(apiUrl + "/categories/tree", {
        headers: {
          lang: languageCode,
        },
      })
      .then((res) => {
        const menuItems = res.data;
        this._isMounted = true;
        this.setState({ menuItems });
      });
    axios
      .get(apiUrl + "/currency", {
        headers: {
          lang: languageCode,
        },
      })
      .then((res) => {
        const currency = res.data;
        this._isExchangeRates = true;
        this.setState({ currency });
      });

    if (isMobile) {
      document.body.classList.add("mobile-view");
    }
    this.hendelUrlSerachParhams();
  }

  hendelUrlSerachParhams() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (query.s !== "" && query.s !== undefined) {
      this.setState({
        serachedValue: query.s,
      });
    }
  }
  handleSetLanguage = (key) => () => {
    document.documentElement.lang = key;
    setLanguage(key);
    const labgInfo = {
      code: key,
    };
    localStorage.setItem("lang", JSON.stringify(labgInfo));
    // console.log(window.location.pathname.substring(3))
    // console.log(window.location)
    let urlParams = "";
    if (
      (window.location.search !== "") &
      (window.location.search !== undefined)
    ) {
      urlParams = window.location.search;
    }
    let url = "/" + key + window.location.pathname.substring(3) + urlParams;
    history.push(url);
    this.sendData(true);
    // window.location.reload();
  };
  sendData = (param) => {
    this.props.parentCallback(param);
  };

  render() {
    // console.log(window.location)

    if (this._isMounted === false) {
      return "";
    }
    const { t } = this.props;
    let curentUser = JSON.parse(localStorage.getItem("user"));
    let currency = this.state.currency;
    let { menuButtonClicked } = this.state;
    var burgerClassName = "";
    var invertColor = "";
    var invertBg = "";
    var searchBoxErrorClass = "";
    if (this.state.searchFieldErorrM) {
      searchBoxErrorClass = "error";
    } else {
      searchBoxErrorClass = "";
    }

    if (menuButtonClicked) {
      burgerClassName = "menu-burger clicked";
      invertColor = "logo invert-color";
      invertBg = "invert";
    } else {
      burgerClassName = "menu-burger";
      invertColor = "logo";
      invertBg = "";
    }
    let languages = [
      {
        code: "hy",
        iso: "HY",
        lable: "Հայերեն",
      },
      {
        code: "en",
        iso: "EN",
        lable: "English",
      },
    ];

    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;

    return (
      <header className={invertBg}>
        {isMobile ? (
          <div className="mobile clear">
            <div className="mobile-menu-wrap clear">
              <div className="left">
                <div className={invertColor}>
                  <a href={`/${languageCode}/`}>
                    {/*<svg*/}
                    {/*  className="main-logo"*/}
                    {/*  xmlns="http://www.w3.org/2000/svg"*/}
                    {/*  viewBox="0 0 79.61 12.18"*/}
                    {/*>*/}
                    {/*  <g id="Layer_2" data-name="Layer 2">*/}
                    {/*    <g id="Layer_1-2" data-name="Layer 1">*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M7.8,5.79H6.38a2.56,2.56,0,0,1,.89-2.07A4.07,4.07,0,0,1,10,2.91a3.77,3.77,0,0,1,2.6.76,2.65,2.65,0,0,1,.81,2.13l-.08,4c0,.46.05.75.18.86a1.14,1.14,0,0,0,.72.15v1a2.21,2.21,0,0,1-.33.05l-.35,0a1.77,1.77,0,0,1-1.11-.27,1.49,1.49,0,0,1-.43-1,2.81,2.81,0,0,1-1.14,1.1,3.69,3.69,0,0,1-1.81.41,3.53,3.53,0,0,1-2.22-.63,2.32,2.32,0,0,1-.81-2,2.09,2.09,0,0,1,1-1.93,7.9,7.9,0,0,1,3.22-.8,2.93,2.93,0,0,0,1.45-.33A1.19,1.19,0,0,0,12,5.69a1.61,1.61,0,0,0-.51-1.32A2.25,2.25,0,0,0,10,4a2.48,2.48,0,0,0-1.73.49A1.71,1.71,0,0,0,7.8,5.79Zm4.13,2.39,0-.73a.74.74,0,0,1-.21.13,13.52,13.52,0,0,1-1.68.29A4.47,4.47,0,0,0,8,8.5a1.42,1.42,0,0,0-.39,1.05,1.55,1.55,0,0,0,.45,1.17,1.72,1.72,0,0,0,1.2.42,2.49,2.49,0,0,0,1.89-.73A3.35,3.35,0,0,0,11.93,8.18Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M14.85,3.25h1.49V4.52a2.64,2.64,0,0,1,1.08-1.17A3.12,3.12,0,0,1,19,2.91a2.61,2.61,0,0,1,1.59.48,2.41,2.41,0,0,1,.87,1.36,3.21,3.21,0,0,1,1.22-1.37,3.35,3.35,0,0,1,1.75-.47,2.47,2.47,0,0,1,2.09.89,4,4,0,0,1,.68,2.5v5.54H25.7V6.4a3.22,3.22,0,0,0-.42-1.85A1.42,1.42,0,0,0,24,4a2.1,2.1,0,0,0-1.65.69,3.06,3.06,0,0,0-.62,2.07v5.12H20.28V6.41a3.35,3.35,0,0,0-.41-1.83A1.41,1.41,0,0,0,18.62,4,2.05,2.05,0,0,0,17,4.72a2.94,2.94,0,0,0-.66,2v5.12H14.85Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M29.46,3.25h2.41V4.48a4.19,4.19,0,0,1,1.25-1.19,2.8,2.8,0,0,1,1.39-.36,2.7,2.7,0,0,1,1.62.46A2.52,2.52,0,0,1,37,4.63a4.16,4.16,0,0,1,1.32-1.3A3.23,3.23,0,0,1,40,2.93a2.6,2.6,0,0,1,2.21.94,4.49,4.49,0,0,1,.72,2.75v5.22H40.32V6.77A3.2,3.2,0,0,0,40,5.14a1.07,1.07,0,0,0-1-.51,1.36,1.36,0,0,0-1.14.59,2.77,2.77,0,0,0-.44,1.66v5H34.89V6.77a3.06,3.06,0,0,0-.32-1.65,1.08,1.08,0,0,0-1-.49,1.34,1.34,0,0,0-1.12.6A2.76,2.76,0,0,0,32,6.88v5H29.46Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M50,9.36h2.56a3.51,3.51,0,0,1-1.44,2.08,5.45,5.45,0,0,1-3,.73A4.82,4.82,0,0,1,44.75,11a4.47,4.47,0,0,1-1.26-3.49,4.38,4.38,0,0,1,1.33-3.39,5,5,0,0,1,3.5-1.21,4.32,4.32,0,0,1,3.16,1.15,5.11,5.11,0,0,1,1.28,3.6v.47H46.16a2.68,2.68,0,0,0,.54,1.66,1.84,1.84,0,0,0,1.55.67,2,2,0,0,0,1.16-.31A1.43,1.43,0,0,0,50,9.36ZM46.16,6.74H50.1a2.38,2.38,0,0,0-.48-1.56,1.63,1.63,0,0,0-1.33-.55,2,2,0,0,0-1.51.6A2.25,2.25,0,0,0,46.16,6.74Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M62.5,11.84H59.93v-1h0a4.73,4.73,0,0,1-1.22,1,3,3,0,0,1-1.39.31,3.51,3.51,0,0,1-2.85-1.28,5.09,5.09,0,0,1-1.06-3.37,5,5,0,0,1,1.07-3.33,3.62,3.62,0,0,1,2.9-1.26,3.66,3.66,0,0,1,1.43.31,2.78,2.78,0,0,1,1.16.88V0H62.5ZM60.06,7.55a3.78,3.78,0,0,0-.52-2.14,1.81,1.81,0,0,0-2.95,0,3.66,3.66,0,0,0-.54,2.14,3.69,3.69,0,0,0,.54,2.13,1.78,1.78,0,0,0,2.94,0A3.77,3.77,0,0,0,60.06,7.55Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M63.6,2V0h2.57V2Zm0,1.23h2.58v8.59H63.6Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-1"*/}
                    {/*        d="M69.8,6H67.38a2.81,2.81,0,0,1,1.16-2.35,5,5,0,0,1,2.88-.75,4.62,4.62,0,0,1,3,.8,2.92,2.92,0,0,1,.92,2.41l-.05,3.93a3.92,3.92,0,0,0,.11,1.26.57.57,0,0,0,.4.37v.14H73.12L72.9,11h-.05a3,3,0,0,1-1.19.85,4.47,4.47,0,0,1-1.69.31,3.25,3.25,0,0,1-2.23-.67A2.28,2.28,0,0,1,67,9.68a2.31,2.31,0,0,1,.7-1.76A4.65,4.65,0,0,1,70.17,7a11.25,11.25,0,0,0,2.48-.52A.72.72,0,0,0,72.8,6a.41.41,0,0,0,0-.11.71.71,0,0,0,0-.14,1.09,1.09,0,0,0-.36-.83,1.57,1.57,0,0,0-1-.3A1.74,1.74,0,0,0,70.22,5,1.28,1.28,0,0,0,69.8,6Zm3,2.27V7.76a5.08,5.08,0,0,1-.57.23c-.11,0-.48.11-1.13.23a2.49,2.49,0,0,0-1.16.43,1,1,0,0,0-.37.82.84.84,0,0,0,.39.75,1.63,1.63,0,0,0,1,.26,1.84,1.84,0,0,0,1.32-.56A2.19,2.19,0,0,0,72.8,8.3Z"*/}
                    {/*      />*/}
                    {/*      <path*/}
                    {/*        className="cls-2"*/}
                    {/*        d="M76.82,11.84V9.05h2.79v2.79Z"*/}
                    {/*      />*/}
                    {/*      <polygon*/}
                    {/*        className="cls-1"*/}
                    {/*        points="4.06 1.26 4.06 0 0 0 0 1.26 1.24 1.26 1.24 10.58 0 10.58 0 11.84 4.06 11.84 4.06 10.58 2.83 10.58 2.83 1.26 4.06 1.26"*/}
                    {/*      />*/}
                    {/*    </g>*/}
                    {/*  </g>*/}
                    {/*</svg>*/}
                    <img src={Logo} alt="logo" className="lamedia_logo" />
                  </a>
                </div>
              </div>

              <div className="right">
                <div
                  className={burgerClassName}
                  onClick={this.handelMenuButtonClick}
                >
                  <i>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </i>
                </div>
              </div>
            </div>
            <div className={this.state.searchHide + " search-box "}>
              <input
                className={searchBoxErrorClass}
                onKeyPress={this.handleKeyPress}
                id="search-input"
                name="serach"
                defaultValue={this.state.serachedValue}
                onChange={this.handleChange}
                type="text"
                placeholder={t("header.searchFieldPlaceholder")}
              />
              <div className="search-icon">
                <img alt="" src={SearchIcon} onClick={this.handleSearch} />
              </div>
              <div className="invalid-feedback">
                {this.state.searchFieldErorrM}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div>
              {/*
        {t('langInfo.title')}
        { {t('langInfo.title' , null , 'en')} }
 
        <button type="button" onClick={this.handleSetLanguage('en')}>
          Switch en
        </button>
        <button type="button" onClick={this.handleSetLanguage('am')}>
          Switch am
        </button>
      */}
            </div>
            <div className="left">
              <div className="logo">
                <a href={`/${languageCode}/`}>
                  {/*<svg className="main-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.61 12.18">*/}
                  {/*  <g id="Layer_2" data-name="Layer 2">*/}
                  {/*    <g id="Layer_1-2" data-name="Layer 1"><path*/}
                  {/*      className="cls-1"*/}
                  {/*      d="M7.8,5.79H6.38a2.56,2.56,0,0,1,.89-2.07A4.07,4.07,0,0,1,10,2.91a3.77,3.77,0,0,1,2.6.76,2.65,2.65,0,0,1,.81,2.13l-.08,4c0,.46.05.75.18.86a1.14,1.14,0,0,0,.72.15v1a2.21,2.21,0,0,1-.33.05l-.35,0a1.77,1.77,0,0,1-1.11-.27,1.49,1.49,0,0,1-.43-1,2.81,2.81,0,0,1-1.14,1.1,3.69,3.69,0,0,1-1.81.41,3.53,3.53,0,0,1-2.22-.63,2.32,2.32,0,0,1-.81-2,2.09,2.09,0,0,1,1-1.93,7.9,7.9,0,0,1,3.22-.8,2.93,2.93,0,0,0,1.45-.33A1.19,1.19,0,0,0,12,5.69a1.61,1.61,0,0,0-.51-1.32A2.25,2.25,0,0,0,10,4a2.48,2.48,0,0,0-1.73.49A1.71,1.71,0,0,0,7.8,5.79Zm4.13,2.39,0-.73a.74.74,0,0,1-.21.13,13.52,13.52,0,0,1-1.68.29A4.47,4.47,0,0,0,8,8.5a1.42,1.42,0,0,0-.39,1.05,1.55,1.55,0,0,0,.45,1.17,1.72,1.72,0,0,0,1.2.42,2.49,2.49,0,0,0,1.89-.73A3.35,3.35,0,0,0,11.93,8.18Z"/><path*/}
                  {/*      className="cls-1"*/}
                  {/*      d="M14.85,3.25h1.49V4.52a2.64,2.64,0,0,1,1.08-1.17A3.12,3.12,0,0,1,19,2.91a2.61,2.61,0,0,1,1.59.48,2.41,2.41,0,0,1,.87,1.36,3.21,3.21,0,0,1,1.22-1.37,3.35,3.35,0,0,1,1.75-.47,2.47,2.47,0,0,1,2.09.89,4,4,0,0,1,.68,2.5v5.54H25.7V6.4a3.22,3.22,0,0,0-.42-1.85A1.42,1.42,0,0,0,24,4a2.1,2.1,0,0,0-1.65.69,3.06,3.06,0,0,0-.62,2.07v5.12H20.28V6.41a3.35,3.35,0,0,0-.41-1.83A1.41,1.41,0,0,0,18.62,4,2.05,2.05,0,0,0,17,4.72a2.94,2.94,0,0,0-.66,2v5.12H14.85Z"/><path*/}
                  {/*      className="cls-1"*/}
                  {/*      d="M29.46,3.25h2.41V4.48a4.19,4.19,0,0,1,1.25-1.19,2.8,2.8,0,0,1,1.39-.36,2.7,2.7,0,0,1,1.62.46A2.52,2.52,0,0,1,37,4.63a4.16,4.16,0,0,1,1.32-1.3A3.23,3.23,0,0,1,40,2.93a2.6,2.6,0,0,1,2.21.94,4.49,4.49,0,0,1,.72,2.75v5.22H40.32V6.77A3.2,3.2,0,0,0,40,5.14a1.07,1.07,0,0,0-1-.51,1.36,1.36,0,0,0-1.14.59,2.77,2.77,0,0,0-.44,1.66v5H34.89V6.77a3.06,3.06,0,0,0-.32-1.65,1.08,1.08,0,0,0-1-.49,1.34,1.34,0,0,0-1.12.6A2.76,2.76,0,0,0,32,6.88v5H29.46Z"/><path*/}
                  {/*      className="cls-1"*/}
                  {/*      d="M50,9.36h2.56a3.51,3.51,0,0,1-1.44,2.08,5.45,5.45,0,0,1-3,.73A4.82,4.82,0,0,1,44.75,11a4.47,4.47,0,0,1-1.26-3.49,4.38,4.38,0,0,1,1.33-3.39,5,5,0,0,1,3.5-1.21,4.32,4.32,0,0,1,3.16,1.15,5.11,5.11,0,0,1,1.28,3.6v.47H46.16a2.68,2.68,0,0,0,.54,1.66,1.84,1.84,0,0,0,1.55.67,2,2,0,0,0,1.16-.31A1.43,1.43,0,0,0,50,9.36ZM46.16,6.74H50.1a2.38,2.38,0,0,0-.48-1.56,1.63,1.63,0,0,0-1.33-.55,2,2,0,0,0-1.51.6A2.25,2.25,0,0,0,46.16,6.74Z"/><path*/}
                  {/*      className="cls-1"*/}
                  {/*      d="M62.5,11.84H59.93v-1h0a4.73,4.73,0,0,1-1.22,1,3,3,0,0,1-1.39.31,3.51,3.51,0,0,1-2.85-1.28,5.09,5.09,0,0,1-1.06-3.37,5,5,0,0,1,1.07-3.33,3.62,3.62,0,0,1,2.9-1.26,3.66,3.66,0,0,1,1.43.31,2.78,2.78,0,0,1,1.16.88V0H62.5ZM60.06,7.55a3.78,3.78,0,0,0-.52-2.14,1.81,1.81,0,0,0-2.95,0,3.66,3.66,0,0,0-.54,2.14,3.69,3.69,0,0,0,.54,2.13,1.78,1.78,0,0,0,2.94,0A3.77,3.77,0,0,0,60.06,7.55Z"/><path className="cls-1" d="M63.6,2V0h2.57V2Zm0,1.23h2.58v8.59H63.6Z"/><path*/}
                  {/*      className="cls-1"*/}
                  {/*      d="M69.8,6H67.38a2.81,2.81,0,0,1,1.16-2.35,5,5,0,0,1,2.88-.75,4.62,4.62,0,0,1,3,.8,2.92,2.92,0,0,1,.92,2.41l-.05,3.93a3.92,3.92,0,0,0,.11,1.26.57.57,0,0,0,.4.37v.14H73.12L72.9,11h-.05a3,3,0,0,1-1.19.85,4.47,4.47,0,0,1-1.69.31,3.25,3.25,0,0,1-2.23-.67A2.28,2.28,0,0,1,67,9.68a2.31,2.31,0,0,1,.7-1.76A4.65,4.65,0,0,1,70.17,7a11.25,11.25,0,0,0,2.48-.52A.72.72,0,0,0,72.8,6a.41.41,0,0,0,0-.11.71.71,0,0,0,0-.14,1.09,1.09,0,0,0-.36-.83,1.57,1.57,0,0,0-1-.3A1.74,1.74,0,0,0,70.22,5,1.28,1.28,0,0,0,69.8,6Zm3,2.27V7.76a5.08,5.08,0,0,1-.57.23c-.11,0-.48.11-1.13.23a2.49,2.49,0,0,0-1.16.43,1,1,0,0,0-.37.82.84.84,0,0,0,.39.75,1.63,1.63,0,0,0,1,.26,1.84,1.84,0,0,0,1.32-.56A2.19,2.19,0,0,0,72.8,8.3Z"/><path className="cls-2" d="M76.82,11.84V9.05h2.79v2.79Z"/><polygon className="cls-1" points="4.06 1.26 4.06 0 0 0 0 1.26 1.24 1.26 1.24 10.58 0 10.58 0 11.84 4.06 11.84 4.06 10.58 2.83 10.58 2.83 1.26 4.06 1.26"/></g>*/}
                  {/*  </g>*/}
                  {/*</svg>*/}

                  <img src={Logo} alt="logo" className="lamedia_logo" />
                </a>
              </div>
              <CommonMenu data={this.state.menuItems} mode="horizontal" />
              <ul className="live-menu-item" date-status="true">
                <li>
                  <NavLink
                    data-status="live"
                    to={`/${languageCode}/live`}
                    activeClassName="active"
                    aria-hidden="true"
                  >
                    Live
                  </NavLink>
                </li>
              </ul>
              <div className="search-box">
                <input
                  className={searchBoxErrorClass}
                  onKeyPress={this.handleKeyPress}
                  id="search-input"
                  name="serach"
                  defaultValue={this.state.serachedValue}
                  onChange={this.handleChange}
                  type="text"
                  placeholder={t("header.searchFieldPlaceholder")}
                />
                <div className="search-icon">
                  <img alt="" src={SearchIcon} onClick={this.handleSearch} />
                </div>
                <div className="invalid-feedback">
                  {this.state.searchFieldErorrM}
                </div>
              </div>
            </div>
            <div className="right">
              {this._isExchangeRates ? (
                <div className="currencyBox">
                  <ul>
                    {currency.map((list, key) => {
                      return (
                        <li key={key}>
                          <span className="name">{list.code}</span>{" "}
                          <span className="value">{list.value}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              <div className="langBox">
                <div className="selected-lang" onClick={this.langToolTip}>
                  <div className="icon-box">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path
                            className="cls-1"
                            d="M18.5,9.5a9,9,0,1,1-9-9A9,9,0,0,1,18.5,9.5ZM1.7,5H17.3M9.5.5v18m-9-9h18M1.7,14H17.3M9.5,18.5c2.36-.68,4.42-4.36,4.49-9m-4.48,9c-2.37-.68-4.43-4.36-4.49-9m4.47-9c-2.36.68-4.42,4.36-4.48,9m4.48-9c2.36.68,4.42,4.36,4.48,9"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="lang-code">
                    {languages.map((list, key) => {
                      if (list.code === languageCode) {
                        return list.iso;
                      }
                      return "";
                    })}
                  </div>
                </div>
                <div
                  className={this.state.langToolTipClass + " lang-tool-tip"}
                  ref={this.setLangWrapperRef}
                >
                  {languages.map((list, key) => {
                    if (list.code === languageCode) {
                      return "";
                    } else {
                      return (
                        <div
                          key={key}
                          type="button"
                          onClick={this.handleSetLanguage(list.code)}
                        >
                          {list.lable}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {menuButtonClicked ? (
          //  true ?
          <div className="mobile-menu">
            <div className="wrapper">
              <CommonMenu data={this.state.menuItems} mode="inline" />
              <ul className="live-menu-item" date-status="true">
                <li>
                  <NavLink
                    onClick={this.onSelectMenuLink}
                    data-status="live"
                    to={`/${languageCode}/live`}
                    activeClassName="active"
                    aria-hidden="true"
                  >
                    Live
                  </NavLink>
                </li>
              </ul>
              <div className="langBox">
                <div className="selected-lang" onClick={this.langToolTip}>
                  <span>
                    {languages.map((list, key) => {
                      if (list.code === languageCode) {
                        return list.lable;
                      }
                      return "";
                    })}
                  </span>
                  <i className="rc-menu-submenu-arrow"></i>
                </div>
                <div className={this.state.langToolTipClass + " lang-tool-tip"}>
                  {languages.map((list, key) => {
                    if (list.code === languageCode) {
                      return "";
                    } else {
                      return (
                        <div
                          key={key}
                          type="button"
                          onClick={this.handleSetLanguage(list.code)}
                        >
                          {list.lable}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </header>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}
const DefaultHeader = connect(mapStateToProps, null, null, { pure: false })(
  Header
);

DefaultHeader.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(DefaultHeader);
