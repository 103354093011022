import React, { Component } from 'react';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'

import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
// import { authHeader } from '../../_helpers';
// import axios from 'axios';
// import Pagination from "react-js-pagination";

// import PostItem from '../post/post'

//import ImageAsset from './image-asset.jpg';
// import Header from '../headerComponent/header'
// import Footer from '../footerComponent/footer'
// const apiUrl = process.env.REACT_APP_API;
//const imageUrl = process.env.REACT_APP_IMAGE_URL;
//import { HashLink as Link } from 'react-router-hash-link';

class ReturnPolicyPage extends Component {
  // constructor(props) {
  //   super(props);
  //   //this.scrollToView = this.scrollToView.bind(this);
  //
  // }
  _isMounted = false;

  componentDidMount(){
    //let currentComponent = this;
    // window.onload = function (event) {
    //   currentComponent.scrollToView()
    // }
    // currentComponent.scrollToView()

  }

  // scrollToView() {
  //   if (this.props.location.hash && this.props.location.hash !== undefined) {
  //     // let currentComponent = this;
  //     var hash = this.props.location.hash.replace('#','');
  //     const elementPosition = document.getElementById(hash).offsetTop-40;
  //     window.scroll({
  //       top: elementPosition,
  //       left: 0,
  //       behavior: "smooth"
  //     });
  //   }
  // }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    // const scrollWithOffset = (el, offset) => {
    //   const elementPosition = el.offsetTop - offset;
    //   window.scroll({
    //     top: elementPosition,
    //     left: 0,
    //     behavior: "smooth"
    //   });
    // }
    window.scroll(0, 0);
    const { t } = this.props;

    return (
      <div>
        <div className="legal-page-container">
          <MetaTags/>
          <div className="wrapper">
            <h1>{t('returnPolicy.pageTitle')}</h1>
            {/*<div className="left">
              <Link  scroll={el => scrollWithOffset(el,40)}  to="#return-policy">
              Վերադարձի քաղաքականություն
              </Link >

              </div>*/}
              <section  id="return-policy">
                {/*<h2>Վերադարձի քաղաքականություն</h2> */}
                <div className="text-box" dangerouslySetInnerHTML={{__html: t('returnPolicy.pageContent')}}>

                </div>
              </section>
              {/* <div className="right">
                <section  id="return-policy">
                <h2>Վերադարձի քաղաքականություն</h2>
                <div className="text-box">
                <p>
                Lamedia-ն ունի անդամագրման, հովանավորման, և գովազդների տեղադրման մի քանի տարբերակների հնարավորություն. Դուք կարող եք իմանալ ավելին այցելելով մեր վեբ կայքը. Ներքևում կարող եք գտնել վերադարձի քաղաքականությունը՝ յուերաքանչյուր տարբերակի համար.
                </p>
                <h3>Անդամագրում</h3>
                <p>
                Դուք կարող եք վերադարձնել կամ չեղարկել ձեր անդամագրումը գնմանը հաջորդող մեկ օրվա ընթացքում.
                </p>
                <h3>
                Հովանավորում
                </h3>
                <p>
                Դուք չեք կարող չեղարկել հովանավորված հոդվածի վճարումից՝ այն հանձնելուց և հաշվի վճարումը կատարելուց հետո.
              </p>
              <h3>Գովազդների Տեղադրում</h3>
              <p>
              Ձեր հանձնած գովազդի ստուգումից և վճարման հաստատումից հետո դուք այլևս չեք կարող չեղարկել ձեր գովազդային տարածքի գնումը.
              <br></br>
              <br></br>
              Կրկնակի գանձման, ինչպես նաև, այլ լրացուցիչ գանձումների խնդիրները կդիտարկվեն և կփոխհատուցվեն՝ վերոնշյալ կանոններին համաձայն:
            </p>

          </div>
        </section>
      </div>
      */}
    </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

ReturnPolicyPage.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(ReturnPolicyPage);
