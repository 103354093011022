import React, { Component } from 'react';
import validator from 'validator';
import FormValidator from '../validations';
import {
    Link,Redirect
} from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { alertActions } from '../../_actions';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'

import PropTypes from 'prop-types';

// Translation Higher Order Component
import {
    translate,
} from 'react-switch-lang';
import { history } from '../../_helpers';
import { userActions } from '../../_actions';
import SocialButton from './SocialButton'
const apiUrl = process.env.REACT_APP_API



class LoginPage extends Component {
    constructor(props) {
        super(props);
        const type=props.type;

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
        this.state = {
            user:{
                email: '',
                password: '',
            },
            submitted: false,
            type: type,
            messageFromServer: '',
            messageColor:'success',
            visible: false,
            redirect:false,
            redirectUrl:'',
            collapse: true,
            fadeIn: true,
            timeout: 300,
            errors: [],
            errorClasses: [],
            countries: [],
            socialLoginCheck:false,
            socialInfo:{
                email:'',
                firstName:'',
                lastName:'',
                token:'',
                type:''
            }
        }
        this.handleSocialLogin = this.handleSocialLogin.bind(this);
        this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.loginUser = this.loginUser.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    onDismiss() {
        this.setState({ visible: false });
    }
    handleValidation(){
        //let fields = this.state.user;
        const { t } = this.props;

        let errors = {};
        let errorClasses = {};
        let formIsValid = true;
        const validationRules = [
            {
                field: 'email',
                method: validator.isEmpty,
                validWhen: false,
                message: t('loginPage.formInfo.email.errorMessage')
            },
            {
                field: 'email',
                method: validator.isEmail,
                validWhen: true,
                message: t('loginPage.formInfo.email.errorMessageEmail')
            },
            {
                field: 'password',
                method: validator.isEmpty,
                validWhen: false,
                message: t('loginPage.formInfo.password.errorMessage')
            },
        ];

        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(this.state.user);
        if(!validation.isValid){
            formIsValid = false;
            for (var variable in validation) {
                if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
                    if(validation[variable]['isInvalid']){
                        errors[variable] = validation[variable]['message'];
                        errorClasses[variable] = "is-invalid";
                    }
                }
            }

        }
        this.setState({errors: errors,errorClasses:errorClasses});
        return formIsValid;
    }
    handleChange(e){
        const name =  e.target.name;
        const user = this.state.user;
        user[name] = e.target.value;
        this.setState({
            user
        });
        // console.log(this.state.user);
        //this.handleValidation();
    }
    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            let currentComponent=this;
            currentComponent.loginUser(event)
        }
    }
    componentDidMount() {
        if(localStorage.getItem('user')) {
            let language = JSON.parse(localStorage.getItem('lang'))
            let languageCode = language.code
            let currentComponent=this;
            currentComponent.setState({
                redirect:true,
                redirectUrl:`/${languageCode}/`
            });
        }
    }

    loginUser(e) {
        e.preventDefault();
        if(this.handleValidation()){
            let language = JSON.parse(localStorage.getItem('lang'))
            let languageCode = language.code
            let { from } = this.props.location.state || { from: { pathname: `/${languageCode}/` } };

            const { email, password } = this.state.user;
            const { dispatch } = this.props
            if (email && password) {
                dispatch(userActions.login(email, password , from));
            }
        }
    }
    handleSocialLogin = (user) => {
        let currentComponent = this
        let requestUrl = '/users/social-login';
        //     email:user._profile.email,
        //     firstName:user._profile.firstName,
        //     lastName:user._profile.lastName,
        //     token:user._token.accessToken,
        //     type:user._provider
        var tokenType = '';
        if(user._provider === 'google'){
            tokenType = user._token.idToken
        }else {
            tokenType = user._token.accessToken
        }
        let postdata = {
            firstName: user._profile.firstName,
            lastName:  user._profile.lastName,
            email:  user._profile.email,
            token:  tokenType,
            type: user._provider
        };
        axios.post(apiUrl+requestUrl,JSON.stringify(postdata),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    'lang':this.props.match.params.lang
                }
            }).then(function(response) {
            currentComponent.setState({
                messageFromServer: response.data.message
            });
            if(response.data.code!==200){
                currentComponent.setState({messageColor:'danger'});
                if(response.data.errors){
                    const res = response.data.errors
                    const errors = currentComponent.state.errors;
                    for (var index of Object.keys(res)) {
                        errors[index] = res[index];
                    }
                    currentComponent.setState({
                        errors
                    });
                }
            }
            else {
                var data = response.data.success.user
                localStorage.setItem('user', JSON.stringify(data));
                let language = JSON.parse(localStorage.getItem('lang'))
                let languageCode = language.code
                window.location = `/${languageCode}/`
            }
        });
    }

    handleSocialLoginFailure = (err) => {
        console.error(err)
    }
    render() {
        if(this.state.redirect){
            return (<Redirect to={this.state.redirectUrl} />);
        }
        const { alert } = this.props;
        const { t } = this.props;
        let language = JSON.parse(localStorage.getItem('lang'))
        let languageCode = language.code
        return (
            <div>
                <MetaTags/>
                <div className="sign-in-container">
                    <div className="table">
                        <div className="cell">
                            <h1>{t('loginPage.pageTitle')}</h1>
                            <div className="row">
                                <input onKeyPress={this.handleKeyPress} type="email" tabIndex='1' placeholder={t('loginPage.formInfo.email.placeholder')} name="email" className={this.state.errorClasses.email} onChange={this.handleChange} />
                                <div className="invalid-feedback">{this.state.errors.email}</div>
                            </div>
                            <div className="row">
                                <Link className="forgot" to={`/${languageCode}/forgot-password`}>{t('loginPage.forgotButtonText')}</Link>
                                <input onKeyPress={this.handleKeyPress} type="password" tabIndex='2' placeholder={t('loginPage.formInfo.password.placeholder')} name="password" className={this.state.errorClasses.password} onChange={this.handleChange} />
                                <div className="invalid-feedback">{this.state.errors.password}</div>
                            </div>
                            <div className="bottom row">
                                {alert && alert.message &&
                                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                                }
                                <div className="info">
                                    {t('loginPage.registerText')} <Link to={`/${languageCode}/sign-up`}>{t('loginPage.registorButtonText')}</Link>.
                                </div>
                                <button type="submit" tabIndex='3' onClick={this.loginUser}>{t('loginPage.loginButtonText')}</button>

                            </div>
                            <div className="social-login-box">
                                <div className="title">{t('loginPage.socialLoginText')}</div>
                                <SocialButton
                                    provider='facebook'
                                    appId='516580402200598'
                                    onLoginSuccess={this.handleSocialLogin}
                                    onLoginFailure={this.handleSocialLoginFailure}
                                >
                                    <svg  viewBox="0 0 110 40" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Login-Copy" transform="translate(-38.000000, -540.000000)">
                                                <g id="Group" transform="translate(38.000000, 540.000000)">
                                                    <rect id="Rectangle-9-Copy" fill="#3B5998" fillRule="nonzero" x="0" y="0" width="110" height="40" rx="4"></rect>
                                                    <path d="M59.3461538,11.1298077 L59.3461538,13.9855769 L57.6478365,13.9855769 C56.3173077,13.9855769 56.0685096,14.6237981 56.0685096,15.5432692 L56.0685096,17.5877404 L59.2379808,17.5877404 L58.8161058,20.7896635 L56.0685096,20.7896635 L56.0685096,29 L52.7584135,29 L52.7584135,20.7896635 L50,20.7896635 L50,17.5877404 L52.7584135,17.5877404 L52.7584135,15.2295673 C52.7584135,12.4927885 54.4350962,11 56.8798077,11 C58.0480769,11 59.0540865,11.0865385 59.3461538,11.1298077 Z" id="facebook" fill="#FFFFFF"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                </SocialButton>
                                <SocialButton
                                    provider='google'
                                    appId='375040050301-kg3oclpbdpkpgppgaekdn0ttauvv9p5f.apps.googleusercontent.com'
                                    onLoginSuccess={this.handleSocialLogin}
                                    onLoginFailure={this.handleSocialLoginFailure}
                                >
                                    <svg viewBox="0 0 110 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Login-Copy" transform="translate(-167.000000, -540.000000)" fillRule="nonzero">
                                                <g id="Group-2" transform="translate(167.000000, 540.000000)">
                                                    <rect id="Rectangle-9-Copy-2" fill="#f4f4f4" x="0" y="0" width="110" height="40" rx="4"></rect>
                                                    <g id="google" transform="translate(46.000000, 11.000000)">
                                                        <path d="M3.98917969,10.877625 L3.362625,13.2166406 L1.07258203,13.2650859 C0.388195313,11.9956992 0,10.5433594 0,9 C0,7.50758203 0.362953125,6.10020703 1.0063125,4.86098438 L1.00680469,4.86098438 L3.04558594,5.23476563 L3.93869531,7.2613125 C3.75176953,7.80626953 3.64988672,8.39126953 3.64988672,9 C3.64995703,9.66065625 3.76962891,10.2936445 3.98917969,10.877625 Z" id="Shape" fill="#FBBB00"></path>
                                                        <path d="M17.8427461,7.3186875 C17.9461055,7.86311719 18,8.42537109 18,9 C18,9.64434375 17.9322539,10.2728672 17.8031953,10.8791367 C17.3650781,12.9422109 16.2202852,14.7436875 14.6344219,16.0185234 L14.6339297,16.0180312 L12.0659766,15.8870039 L11.7025312,13.6181953 C12.7548281,13.0010625 13.5772031,12.0352852 14.0103984,10.8791367 L9.19785937,10.8791367 L9.19785937,7.3186875 L14.0806055,7.3186875 L17.8427461,7.3186875 Z" id="Shape" fill="#518EF8"></path>
                                                        <path d="M14.6338945,16.0180312 L14.6343867,16.0185234 C13.0920469,17.2582383 11.1327891,18 9,18 C5.57258203,18 2.59270312,16.0843008 1.07258203,13.2651211 L3.98917969,10.8776602 C4.74922266,12.9061055 6.70598437,14.3500781 9,14.3500781 C9.98602734,14.3500781 10.909793,14.0835234 11.7024609,13.6181953 L14.6338945,16.0180312 Z" id="Shape" fill="#28B446"></path>
                                                        <path d="M14.7446719,2.07196875 L11.8290586,4.4589375 C11.0086875,3.94614844 10.0389375,3.64992187 9,3.64992187 C6.65405859,3.64992187 4.66069922,5.16012891 3.93873047,7.2613125 L1.00680469,4.86098437 L1.0063125,4.86098437 C2.50417969,1.97307422 5.52164062,0 9,0 C11.1837305,0 13.1859844,0.777867188 14.7446719,2.07196875 Z" id="Shape" fill="#F14336"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </SocialButton>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;

    return {
        alert,
        loggingIn,
    };

}

const Login = connect(mapStateToProps)(LoginPage);
Login.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(Login);
