import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import { authHeader } from '../../../_helpers';
import Pagination from "react-js-pagination";


import PropTypes from 'prop-types';

// Translation Higher Order Component
import {
    translate,
} from 'react-switch-lang';



const apiUrl = process.env.REACT_APP_API

class SubmitedPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            post:[],
            activePage: 1,
            totalCount:'',
            pp:'',
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePurchase = this.handlePurchase.bind(this);
        this.handleSubmitedPosts = this.handleSubmitedPosts.bind(this);
    }
    _isMounted = false;

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidMount(){
        let currentComponent = this;
        currentComponent._isMounted = true;
        currentComponent.handleSubmitedPosts();
        axios.get(apiUrl+'/submittedposts/',{headers:authHeader()})
            .then(function(response) {
                const total = response.data.total;
                if(currentComponent._isMounted){
                    currentComponent.setState({ totalCount:total });
                }
            });
        axios.get(apiUrl+'/submittedposts/',{headers:authHeader()})
            .then(function(response) {
                const page = response.data.pp;
                if(currentComponent._isMounted){
                    currentComponent.setState({ pp:page });
                }
            });
    }
    handleSubmitedPosts(pageNumber) {
        let currentComponent = this;
        const queryParams = {};
        if(pageNumber !== undefined && pageNumber !== '') {
            queryParams.p = pageNumber;
        }
        axios.get(apiUrl+'/submittedposts/',{params:queryParams,headers:authHeader()})
            .then(function(response) {
                const posts = response.data.posts;
                if(currentComponent._isMounted){
                    currentComponent.setState({post:posts,activePage:pageNumber});
                }
                // console.log(response.data)
            });
    }
    handlePageChange(pageNumber) {
        this.handleSubmitedPosts(pageNumber);

    }
    handlePurchase(e){
        var id = e.target.id
        let postdata= {
            payment:id
        }

        axios.post(apiUrl+'/payment/resend/',JSON.stringify(postdata),{
            headers:authHeader()
        }).then(function(response) {
            if(response.data.code === 200 ){
                if(response.data.success.url !== undefined || response.data.success.url !== '') {
                    window.location = response.data.success.url
                }
            }
        });
    }

    render() {
        if(this._isMounted === false) {
            return false;
        }
        // console.log(this.state.totalCount)
        // console.log(this.state.post)

        const { t } = this.props;

        return (
            <div className="submited-post-list">
                <div className="section-title">{t('myPostPage.submitedSection.title')}</div>
                <div className="section-description">{t('myPostPage.submitedSection.description')}</div>
                <div className="submited-post-container">

                    {this.state.post.map((list , key) => {
                        const dateToFormat = list.createdAt;

                        return(
                            <div key={key} className="post-item clear">
                                <div className="left">
                                    <div className="date"><span>{t('myPostPage.submitedSection.createdAt')} </span><Moment format="DD.MM.YYYY" date={dateToFormat} /></div>
                                    <div className="category"><span>{t('myPostPage.submitedSection.category')} </span><span>
                      {!list.category ?
                          list.otherCategory
                          :
                          list.category.title
                      }
                    </span></div>
                                    <div className="title" title={list.title}>{list.title}</div>
                                </div>
                                {list.statusCode === "F" && (list.payment !== undefined || list.payment !== '')?
                                    <div className="right button-block">
                                        <div className="resend-button" onClick={this.handlePurchase} id={list.payment}>{t('myPostPage.submitedSection.payButtonText')}</div>
                                    </div>
                                    :
                                    <div className="right">
                                        <div className="status" data-statuscode={list.statusData.code} data-status={list.statusData.text.toLowerCase()}>
                                            <span></span>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                {this.state.pp < this.state.totalCount ?
                    <Pagination
                        innerClass="pagination clear"
                        hideFirstLastPages
                        pageRangeDisplayed={5}
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.pp}
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        nextPageText={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path className="cls-1" d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"/>
                                    </g>
                                </g>
                            </svg>
                        }
                        prevPageText={
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243.01 414.68">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path className="cls-1" d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"/>
                                    </g>
                                </g>
                            </svg>
                        }
                    />
                    :
                    ''
                }
            </div>
        );
    }
}

SubmitedPost.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(SubmitedPost);
