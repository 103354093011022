import React, { Component } from "react";

import MetaTags from "../metaTags";
import Footer from "../footerComponent/footer";

class PrivacyPolicy extends Component {
  render() {
    window.scroll(0, 0);

    return (
      <div>
        <div className="legal-page-container">
          <MetaTags />
          <div className="wrapper">
            <h1 className="title">Privacy Policy</h1>
            <div className="content-box">
              <div className="wrap-box">
                <p>
                  At lamedia.am, accessible at https://lamedia.am/, one of our
                  main priorities is the privacy of our visitors. By reading
                  this document, you will learn what types of data is collected
                  and recorded by lamedia.am and how we use it.
                </p>
                <br></br>
                <p>
                  In case of questions about our Privacy Policy, do not hesitate
                  to contact us at info@lamedia.am.
                </p>
              </div>
              <div className="wrap-box">
                <h2>Personal Identification Information</h2>
                <div className="text-box">
                  <p>
                    We collect personal identification information from the
                    users at the registration stage. To use lamedia.am’s
                    services and help us identify the validity of the account we
                    request appropriate information, such as name, email
                    address, mailing address, phone number. It is collected only
                    if a user voluntarily submits such information to us. Users
                    can always refuse to supply identification information
                    personally, except that it may prevent them from engaging in
                    certain site-related activities.
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Non-personal Identification Information</h2>
                <div className="text-box">
                  <p>
                    lamedia.am follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this and a part of hosting services'
                    analytics. The information collected by log files includes
                    internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit
                    pages, and possibly the number of clicks. These are not
                    linked to any personally identifiable information. The
                    purpose of the information is to analyze trends, administer
                    the site, track users' movement on the website, and gather
                    demographic information.
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Cookies and Web Beacons</h2>
                <div className="text-box">
                  <p>
                    {`lamedia.am uses 'cookies'. These cookies are used to store information, including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type or other information.`}
                  </p>
                  <br></br>
                  <p>{`You can disable cookies through your browser options. To learn more about the cookie management, check out your browser'.`}</p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Uses of Information </h2>
                <div className="text-box">
                  <p>
                    We use the information collected from the user in the
                    following ways:
                  </p>
                  <p>1. To enhance and personalize their experience</p>
                  <p>2. To improve our platform and customer services</p>
                  <p>3. To administer content and publish it on the website</p>
                  <p>
                    4. To send emails and establish a connection with a user
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Forms of Disclosure</h2>
                <div className="text-box">
                  <p>
                    lamedia.am using the services of third-party providers to
                    operate our website. These service providers have or might
                    request access to personal information needed to perform
                    such functions on our behalf and allowed us to use your
                    personal information to provide these services to us. The
                    services include credit card processing, analytics service
                    providers, and other security services that we believe
                    comply with GDPR requirements. Per their request, we might
                    transfer as much of your personal information to them as
                    necessary for them to perform their services.
                  </p>
                  <br></br>
                  <p>
                    We may also disclose your personal information in the
                    following situations:
                  </p>
                  <p>1. When the authorities require us to do so by law;</p>
                  <p>
                    2. In case of a criminal investigation or illegal
                    activities;
                  </p>
                  <p>
                    3. To defend yours and our rights or address financial
                    risks.
                  </p>
                  <br></br>
                  <p>
                    In those cases, your data will only be used to protect your
                    rights and our legal interests.
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Third-Party Websites and Links</h2>
                <div className="text-box">
                  <p>
                    When using lamedia.am you might share data, images, and
                    similar content via other website and third parties.
                    lamedia.am will not use the content you provide in other
                    purposes than the ones you have indicated. lamedia.am uses
                    third parties such as YouTube API and Google, to directly
                    publish videos or access provided content.
                  </p>
                  <br></br>
                  <p>{`lamedia.am's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.`}</p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Children's Information</h2>
                <div className="text-box">
                  <p>
                    Another part of our priority is adding protection for
                    children while using the internet. We encourage parents and
                    guardians to observe, take part in, and/or monitor and guide
                    their online activity.
                  </p>
                  <br></br>
                  <p>
                    lamedia.am does not knowingly collect any Personal
                    Identifiable Information from children. If you think your
                    child provided this kind of information on our website, we
                    strongly encourage you to contact us immediately. We will
                    make our best efforts to remove such information from our
                    records promptly.
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Changes to Privacy Policy</h2>
                <div className="text-box">
                  <p>
                    lamedia.am has the discretion to update this privacy policy.
                    We encourage Users to frequently check this page for any
                    changes to stay informed about how we are helping to protect
                    the personal information we collect. By using our website
                    you acknowledge and agree that it is your responsibility to
                    review this privacy policy periodically.
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Online Privacy Policy Only</h2>
                <div className="text-box">
                  <p>
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website regarding the
                    information that they shared and/or collect in lamedia.am.
                    This policy does not apply to any information collected
                    offline or via channels other than this website.
                  </p>
                </div>
              </div>

              <div className="wrap-box">
                <h2>Consent</h2>
                <div className="text-box">
                  <p>
                    By using our website, you consent to our Privacy Policy and
                    agree to its Terms and Conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicy;
