import React, { Component } from 'react';
import axios from 'axios';
import validator from 'validator';
import FormValidator from '../validations';
import Footer from '../footerComponent/footer'

import MetaTags from '../metaTags'


import PropTypes from 'prop-types';
 
// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';
const apiUrl = process.env.REACT_APP_API;
var qs = require('query-string');


class ContactPage extends Component {
  constructor(props) {
    super(props);
    // const type=props.type;
    this.state = {
        mail:{
          fullName: '',
          subject:'',
          email: '',
          phoneNumber:'',
          message:''
        },
        messageFromServer: '',
        messageColor:'success',
        errors: [],
        errorClasses: [],
    }
    this.handleChange = this.handleChange.bind(this);
   // this.onDismiss = this.onDismiss.bind(this);
    this.sendMail = this.sendMail.bind(this);
  }
  _isMounted = false;

  componentDidMount(){
      // let currentComponent = this;


  }

  handleValidation(){
      // let fields = this.state.mail;
    const { t } = this.props;

      let errors = {};
      let errorClasses = {};
      let formIsValid = true;
      const validationRules = [
        {
          field: 'fullName',
          method: validator.isEmpty,
          validWhen: false,
          message: t('contact.formInfo.fullName.errorMessage')
        },
        {
          field: 'email',
          method: validator.isEmpty,
          validWhen: false,
          message: t('contact.formInfo.email.errorMessage')
        },
        {
          field: 'email',
          method: validator.isEmail,
          validWhen: true,
          message: t('contact.formInfo.email.errorMessageEmail')
        },
        {
          field: 'subject',
          method: validator.isEmpty,
          validWhen: false,
          message: t('contact.formInfo.subject.errorMessage')
        },
        {
          field: 'phoneNumber',
          method: validator.isNumeric,
          validWhen: true,
          message: t('contact.formInfo.phoneNumber.errorMessagePhoneNumber')
        },
        {
          field: 'message',
          method: validator.isEmpty,
          validWhen: false,
          message: t('contact.formInfo.message.errorMessage')
        }

      ];

      const validatorObj = new FormValidator(validationRules);
      const validation = validatorObj.validate(this.state.mail);
      if(!validation.isValid){
          formIsValid = false;
          for (var variable in validation) {
            if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
              if(validation[variable]['isInvalid']){
                errors[variable] = validation[variable]['message'];
                errorClasses[variable] = "is-invalid";
              }
            }
          }

      }
      this.setState({errors: errors,errorClasses:errorClasses});
      return formIsValid;
   }
  handleChange(e){
    const name =  e.target.name;
    const mail = this.state.mail;
    mail[name] = e.target.value;
    this.setState({
        mail
    });
    // console.log(this.state.user);
    //this.handleValidation();
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  sendMail(e) {
    // let currentComponent = this;

    if(this.handleValidation()){
      let requestUrl = '/pages/contact';
      let currentComponent = this;
      let postdata = {
          fullName: this.state.mail.fullName,
          subject:  this.state.mail.subject,
          email:  this.state.mail.email,
          phoneNumber:  this.state.mail.phoneNumber,
          message: this.state.mail.message,
        };

        axios.post(apiUrl+requestUrl,qs.stringify(postdata),
           {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                    'lang' : this.props.match.params.lang
                 
              }
            }).then(function(response) {
              // console.log(response.data)
                currentComponent.setState({
                    messageFromServer: response.data.success.message
                });
                if(response.data.code===200) {

                  currentComponent.fullName.value = "";
                  currentComponent.subject.value = "";
                  currentComponent.email.value = "";
                  currentComponent.phoneNumber.value = "";
                  currentComponent.message.value = "";

                  setTimeout(function(){
                    currentComponent.setState({
                        messageFromServer: ''
                    });
                  }, 2000);
                }
        });
    }
  }

  render() {
    window.scroll(0, 0);
    const { t } = this.props;
    return (
      <div>
        <div className="contact-page-container">
          <MetaTags/>
          <div className="table">
            <div className="cell">
              <h1>{t('contact.pageTitle')}</h1>
              <div className="clear">
                <div className="right">

                  <div className="row">
                    <input type="text" placeholder={t('contact.formInfo.fullName.placeholder')} name="fullName" ref={el => this.fullName = el} className={this.state.errorClasses.fullName} onChange={this.handleChange} />
                    <div className="invalid-feedback">{this.state.errors.fullName}</div>
                  </div>
                  <div className="row">
                    <input type="email" placeholder={t('contact.formInfo.email.placeholder')} name="email" ref={el => this.email = el} className={this.state.errorClasses.email}  onChange={this.handleChange} />
                    <div className="invalid-feedback">{this.state.errors.email}</div>
                  </div>
                  <div className="row">
                    <input type="text" placeholder={t('contact.formInfo.phoneNumber.placeholder')} ref={el => this.phoneNumber = el} name="phoneNumber" className={this.state.errorClasses.phoneNumber}  onChange={this.handleChange} />
                    <div className="invalid-feedback">{this.state.errors.phoneNumber}</div>
                  </div>
                  <div className="row">
                    <input type="text" placeholder={t('contact.formInfo.subject.placeholder')} name="subject" ref={el => this.subject = el} className={this.state.errorClasses.subject} onChange={this.handleChange} />
                    <div className="invalid-feedback">{this.state.errors.subject}</div>
                  </div>
                  <div className="row">
                    <textarea placeholder={t('contact.formInfo.message.placeholder')} ref={el => this.message = el} name="message" className={this.state.errorClasses.message}  onChange={this.handleChange} ></textarea>
                    <div className="invalid-feedback">{this.state.errors.message}</div>
                  </div>

                  <div className="bottom row">
                    <button  onClick={this.sendMail}>{t('contact.formInfo.buttonText')}</button>
                  </div>
                  {this.state.messageFromServer ?
                    <div className="message">{this.state.messageFromServer}</div>
                  :
                    ''
                  }
                </div>
                <div className="left">
                  <div className="info">
                    <div className="item" dangerouslySetInnerHTML={{__html: t('contact.addressText')}}>
                      
                    </div>
                    <div className="item">
                      <span><a href="callto:+374 95 666 300">+374 95 666 300</a></span>
                    </div>
                    <div className="item">
                      <span><a href="mailto:info@lamedia.am">info@lamedia.am</a></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



ContactPage.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(ContactPage);