import React, { Component } from 'react';
import { authHeader } from '../../../_helpers';

import Moment from 'react-moment';
import ReactModal from 'react-modal';
import axios from 'axios';
import { Lightbox } from "react-modal-image";


import PropTypes from 'prop-types';

// Translation Higher Order Component
import {
  translate,
} from 'react-switch-lang';


const imageUrl = process.env.REACT_APP_IMAGE_URL;
const apiUrl = process.env.REACT_APP_API;


class AdvertisementItemList extends Component {
  constructor(props) {
    super(props);
    const posts = props.posts
    const cancel = props.cancel
    this.state = {
      data:posts,
      cancel:cancel,
      showModal: false,
      thankYou:false,
      urlToLargeImageFile:'',
      open:false
    }
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCancelAdd = this.handleCancelAdd.bind(this);
    this.zoomImage = this.zoomImage.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.handlePurchase = this.handlePurchase.bind(this);

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let data = nextProps.posts
    let cancel = nextProps.cancel
    this.setState({
      data,
      cancel,
      cancelId:'',
    })

  }
  zoomImage(e){
    var imageUrl = e.target.getAttribute('data-image')
    if(imageUrl !== undefined || imageUrl !== ''){

      this.setState({
        open:true,
        urlToLargeImageFile:imageUrl
      })
    }
  }
  handleCancelAdd() {
    const currentComponent = this;
    axios.post(apiUrl+'/commercial/reject/'+this.state.cancelId,{},{
      headers:authHeader()
    }).then(function(response) {
      if(response.data.code === 200 ){
        var advertisementId = response.data.advertisement.id
        // var element = document.getElementById(advertisementId);
        // element.parentNode.removeChild(element);

        var advertisement = document.getElementById(advertisementId+'status')
        if(advertisement) {
          advertisement.dataset.status = 'canceled'
        }
        currentComponent.setState({thankYou:true})
        currentComponent.props.reRender();
      }
    });
  }
  handleOpenModal (e) {
    this.setState({ showModal: true, cancelId:e.target.id,thankYou:false});
  }

  handleCloseModal () {
    this.setState({ showModal: false,cancelId:'' });
  }
  closeLightbox(){
    this.setState({
      open:false,
    })
  }
  handlePurchase(e){
    var id = e.target.id
    let postdata= {
      payment:id
    }

    axios.post(apiUrl+'/payment/resend/',JSON.stringify(postdata),{
      headers:authHeader()
    }).then(function(response) {
      if(response.data.code === 200 ){
        if(response.data.success.url !== undefined || response.data.success.url !== '') {
          window.location = response.data.success.url
        }
      }
    });
  }
  render(){

    const listItem  = this.state.data
    const currentComponent = this
    if(this.state.thankYou === true) {
      window.setTimeout(function(){
        currentComponent.handleCloseModal()
      }, 3000);
    }
    const { t } = this.props;

    // console.log(listItem)
    return (
        <div>
          <ReactModal
              isOpen={this.state.showModal}
              contentLabel="onRequestClose Example"
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="ReactModalPortal cancel-add"
          >
            {this.state.thankYou ?
                <div>
                  <div className="title">{t('myAdvertisement.submitedSection.popUpInfo.thankyouTitle')}</div>
                  <div className="description">{t('myAdvertisement.submitedSection.popUpInfo.thankyouDescription')}</div>
                </div>
                :
                <div>
                  <div className="title">{t('myAdvertisement.submitedSection.popUpInfo.cancelTitle')}</div>
                  <div className="description">{t('myAdvertisement.submitedSection.popUpInfo.cancelDescription')}</div>
                  <button onClick={this.handleCancelAdd}>{t('myAdvertisement.submitedSection.popUpInfo.submitButtonText')}</button>
                  <button onClick={this.handleCloseModal}>{t('myAdvertisement.submitedSection.popUpInfo.cancelButtonText')}</button>
                </div>
            }
          </ReactModal>
          { this.state.open ?
              <Lightbox
                  medium={this.state.urlToLargeImageFile}
                  onClose={this.closeLightbox}
                  hideDownload={true}
              />
              :
              ''
          }
          {listItem.map((list , key) => {
            const dateToFormat = list.createdAt;
            //console.log(list)
            return(
                <div id={list.id} key={key} className="post-item ">
                  <div className="left clear">
                    <div className="image-box" onClick={this.zoomImage} data-image={imageUrl+list.image} style={{ backgroundImage: `url(${imageUrl+list.image})` }} >
                      <img onClick={this.zoomImage} data-image={imageUrl+list.image} className={list.block.type} alt="" src={imageUrl+list.image}/>
                    </div>
                    <div className="text-box">
                      <div className="title" title={list.block.name}>{list.block.name}</div>
                      <div className="date"><span>{t('myAdvertisement.submitedSection.createdAt')} </span><Moment format="DD.MM.YYYY" date={dateToFormat} /></div>
                      <div className="from-to">
                        <span>{t('myAdvertisement.submitedSection.duration')} </span>
                        <span><Moment format="DD.MM.YYYY" date={list.reserveDays[0]} /> - <Moment format="DD.MM.YYYY" date={list.reserveDays[list.reserveDays.length-1]} /></span>
                        <span></span>
                      </div>
                      <div className="price">
                        <span>{t('myAdvertisement.submitedSection.price')} </span>
                        <span>{list.reserveDays.length*list.block.perDayCost + ' ֏'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="table">
                      <div className="cell">
                        {
                          this.state.cancel !== undefined && this.state.cancel === 'true' ?
                              <div className="cancel-button" onClick={this.handleOpenModal} id={list.id}>{t('myAdvertisement.submitedSection.cancelButtonText')}</div>
                              :

                              list.statusCode === "F" && (list.payment !== undefined || list.payment !== '')?
                                  <div className="resend-button" onClick={this.handlePurchase} id={list.payment}>{t('myAdvertisement.submitedSection.payButtonText')}</div>
                                  :
                                  <div id={list.id+'status'} className="status" data-statuscode={list.status.code} data-status={list.status.text.toLowerCase()}>
                                    <span></span>
                                  </div>
                        }
                      </div>
                    </div>
                  </div>

                </div>
            )
          })}

        </div>
    )
  }

}

AdvertisementItemList.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate(AdvertisementItemList);
