import React, { Component } from "react";
import validator from "validator";
import FormValidator from "../validations";
import MetaTags from "../metaTags";
import Footer from "../footerComponent/footer";

import axios from "axios";
import Select from "react-select";

import ReactModal from "react-modal";
import { authHeader } from "../../_helpers";
import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";
const apiUrl = process.env.REACT_APP_API;


class UserEdit extends Component {
  constructor(props) {
    super(props);
    const type = props.type;
    this.state = {
      user: {
        firstName: "",
        lastName: "",
        country: "",
        phoneNumber: ""
      },
      userNewPass: {
        password: "",
        repassword: ""
      },
      editSuccessMessage: "",
      selectedOption: "",
      options: {},
      changePassword: true,
      type: type,
      messageFromServer: "",
      messageColor: "success",
      visible: false,
      redirect: false,
      redirectUrl: "",
      errors: [],
      errorClasses: [],
      countries: [],
      successEdited: false,
      checkeBoxStatus: false,
      checkeBoxErrorMessage: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.editUser = this.editUser.bind(this);
    this.countryOptions = this.countryOptions.bind(this);
    this.logChange = this.logChange.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.ChangePassword = this.ChangePassword.bind(this);
    this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCheckeBox = this.handleCheckeBox.bind(this);
  }
  _isMounted = false;
  _isLoaded = false;
  onDismiss() {
    this.setState({ visible: false });
  }
  handleOpenModal(e) {
    this.setState({ showModal: true, thankYou: false });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleValidation() {
    const { t } = this.props;

    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: t("userAccount.formInfo.firstName.errorMessage")
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: t("userAccount.formInfo.lastName.errorMessage")
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  handleChange(e) {
    const name = e.target.name;
    const user = this.state.user;
    user[name] = e.target.value;
    this.setState({
      user,
      successEdited: false
    });
  }
  handlePasswordChange(e) {
    const name = e.target.name;
    const userNewPass = this.state.userNewPass;
    userNewPass[name] = e.target.value;
    this.setState({
      userNewPass
    });
  }
  componentDidMount() {
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    axios
        .get(apiUrl + "/countries", {
          headers: { lang: languageCode }
        })
        .then(res => {
          const countries = res.data;
          this._isMounted = true;
          this.setState({ countries });
        });
    axios
        .get(apiUrl + "/users/settings", {
          headers: authHeader()
        })
        .then(res => {
          this._isLoaded = true;
          const checkeBoxInfo = res.data.success.settings;
          if (checkeBoxInfo.value === 1) {
            this.setState({ checkeBoxInfo, checkeBoxStatus: true });
          } else {
            this.setState({ checkeBoxInfo, checkeBoxStatus: false });
          }
        });
    let curentUser = JSON.parse(localStorage.getItem("user"));
    if (curentUser !== undefined && curentUser !== "") {
      this.setState({
        user: {
          firstName: curentUser.firstName,
          lastName: curentUser.lastName,
          country: curentUser.country,
          phoneNumber: curentUser.phoneNumber
        }
      });
    }
  }

  countryOptions() {
    var countryList = this.state.countries;
    var option = {};
    var sayHello = Object.keys(countryList).map(function(i) {
      option = {
        value: countryList[i].id,
        label: countryList[i].name
      };
      return option;
    });
    return sayHello;
  }
  ChangePassword() {
    if (this.handlePasswordValidation()) {
      let currentComponent = this;
      let requestUrl = "/users/change-password";
      let postdata = {
        password: this.state.userNewPass.password,
        repeatPassword: this.state.userNewPass.repassword
      };

      axios
          .post(apiUrl + requestUrl, JSON.stringify(postdata), {
            headers: authHeader()
          })
          .then(function(response) {
            currentComponent.setState({
              messageFromServer: response.data.message
            });
            if (response.data.code === 200) {
              currentComponent.setState({
                thankYou: true
              });
              setTimeout(function() {
                currentComponent.handleCloseModal();
              }, 3000);
            }
            // console.log(response.data)
          });
    }
  }
  handlePasswordValidation() {
    const { t } = this.props;

    let fields = this.state.userNewPass;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "password",
        method: validator.isEmpty,
        validWhen: false,
        message: t("userAccount.formInfo.password.errorMessage")
      },
      {
        field: "password",
        method: validator.isLength,
        args: [
          {
            min: 8
          }
        ],
        validWhen: true,
        message: t("userAccount.formInfo.password.errorMessagePassword")
      },
      {
        field: "repassword",
        method: validator.isEmpty,
        validWhen: false,
        message: t("userAccount.formInfo.rePassword.errorMessage")
      },
      {
        field: "repassword",
        method: validator.equals,
        validWhen: true,
        args: { comparison: fields.password },
        message: t("userAccount.formInfo.rePassword.errorMessagePassword")
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.userNewPass);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  editUser(e) {
    let currentComponent = this;
    const { t } = this.props;

    if (this.handleValidation()) {
      let curentUser = JSON.parse(localStorage.getItem("user"));
      let requestUrl = "/users/update/";
      let postdata = {
        firstName: this.state.user.firstName,
        lastName: this.state.user.lastName
        // country: this.state.user.country,
        // phoneNumber: this.state.user.phoneNumber,
      };
      if (this.state.user.country) {
        postdata.country = this.state.user.country;
      }
      if (this.state.user.phoneNumber) {
        postdata.phoneNumber = this.state.user.phoneNumber;
      }
      axios
          .post(apiUrl + requestUrl, JSON.stringify(postdata), {
            headers: authHeader()
          })
          .then(function(response) {
            currentComponent.setState({
              messageFromServer: response.data.message
            });
            if (response.data.code !== 200) {
              currentComponent.setState({ messageColor: "danger" });
              if (response.data.errors) {
                const res = response.data.errors;
                const errors = currentComponent.state.errors;
                for (var index of Object.keys(res)) {
                  errors[index] = res[index];
                }
                currentComponent.setState({
                  errors
                });
              }
            } else {
              let userToken = curentUser.token;
              var userData = response.data.user;

              userData.token = userToken;
              localStorage.removeItem("user");
              localStorage.setItem("user", JSON.stringify(userData));
              currentComponent.setState({
                successEdited: true,
                editSuccessMessage: t("userAccount.updatedButtonText")
              });
            }
          });
    }
  }
  handleCheckeBox(e) {
    let requestUrl = "/users/settings";
    let checkboxVal = "";
    let currentComponent = this;
    if (e.target.checked) {
      this.setState({
        checkeBoxStatus: true
      });
      checkboxVal = 1;
    } else {
      this.setState({
        checkeBoxStatus: false
      });
      checkboxVal = 0;
    }
    let postdata = {
      setting: this.state.checkeBoxInfo.id,
      value: checkboxVal
    };
    axios
        .post(apiUrl + requestUrl, JSON.stringify(postdata), {
          headers: authHeader()
        })
        .then(function(response) {
          if (response.data.code !== 200) {
            currentComponent.setState({ messageColor: "danger" });
            if (response.data.error) {
              currentComponent.setState({
                //checkeBoxStatus: true,
                checkeBoxErrorMessage: response.data.error.message
              });
            }
          } else {
            currentComponent.setState({
              checkeBoxErrorMessage: ""
              //checkeBoxStatus: false,
            });
          }
        });
  }
  logChange(val) {
    // var selectedOptionName = val.label
    var selectedOptionValue = val.value;
    const user = this.state.user;

    var country = "country";
    user[country] = selectedOptionValue;
    this.setState({
      user,
      successEdited: false

      // selectedOption:selectedOptionName
    });
    this.handleValidation();
  }
  render() {
    // console.log(this.countryOptions())
    if (this._isLoaded !== true || this._isMounted !== true) {
      return (
          <div className="loader-box">
          <span className="loader">
            <span className="loader-inner"></span>
          </span>
          </div>
      );
    }

    let curentUser = JSON.parse(localStorage.getItem("user"));
    let checkeBoxInfo = this.state.checkeBoxInfo;
    // console.log(checkeBoxInfo)
    let countryData = this.countryOptions();
    const { t } = this.props;

    return (
        <div>
          <MetaTags />
          <ReactModal
              isOpen={this.state.showModal}
              contentLabel="onRequestClose Example"
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="ReactModalPortal change-password-popup"
          >
            {this.state.thankYou ? (
                <div>
                  <div className="title">
                    {t("userAccount.popUpInfo.thankyouTitle")}
                  </div>
                  <div className="description">
                    {t("userAccount.popUpInfo.thankyouDescription")}
                  </div>
                </div>
            ) : (
                <div>
                  <div className="title">
                    {t("userAccount.popUpInfo.popUpTitle")}
                  </div>
                  <div className="description">
                    {t("userAccount.popUpInfo.popUpDescription")}
                  </div>
                  <div className="row">
                    <input
                        autoComplete="new-password"
                        tabIndex="1"
                        type="password"
                        placeholder={t("userAccount.formInfo.password.placeholder")}
                        name="password"
                        value={this.state.userNewPass.password}
                        className={this.state.errorClasses.password}
                        onChange={this.handlePasswordChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.password}
                    </div>
                  </div>
                  <div className="row">
                    <input
                        autoComplete="re-new-password"
                        tabIndex="2"
                        type="password"
                        placeholder={t("userAccount.formInfo.rePassword.placeholder")}
                        name="repassword"
                        value={this.state.userNewPass.repassword}
                        className={this.state.errorClasses.repassword}
                        onChange={this.handlePasswordChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.repassword}
                    </div>
                  </div>
                  <button onClick={this.ChangePassword}>
                    {t("userAccount.submitButtonText")}
                  </button>
                  <button onClick={this.handleCloseModal}>
                    {t("userAccount.cancelButtonText")}
                  </button>
                </div>
            )}
          </ReactModal>
          <div className="account-edit-container">
            <div className="table">
              <div className="cell">
                <h1>{t("userAccount.pageTitle")}</h1>
                <div className="section-description">
                  {t("userAccount.pageDescription")}
                </div>
                <div className="row top clear">
                  <div className="email-text">{curentUser.email}</div>
                  <div className="change-password-button">
                  <span onClick={this.handleOpenModal}>
                    {t("userAccount.changePasswordButton")}
                  </span>
                  </div>
                </div>
                <div className="row clear">
                  <input
                      type="text"
                      placeholder={t("userAccount.formInfo.firstName.placeholder")}
                      name="firstName"
                      value={this.state.user.firstName}
                      className={this.state.errorClasses.firstName}
                      onChange={this.handleChange}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.firstName}
                  </div>
                </div>
                <div className="row clear">
                  <input
                      type="text"
                      placeholder={t("userAccount.formInfo.lastName.placeholder")}
                      name="lastName"
                      value={this.state.user.lastName}
                      className={this.state.errorClasses.lastName}
                      onChange={this.handleChange}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.lastName}
                  </div>
                </div>
                <div className="row country-row clear">
                  <Select
                      classNamePrefix="react-select"
                      isMulti={false}
                      isSearchable={false}
                      value={[
                        countryData.find(
                            opt => opt.value === this.state.user.country
                        )
                      ]}
                      name="country"
                      options={countryData}
                      onChange={this.logChange}
                      className={this.state.errorClasses.country + " react-select"}
                      placeholder={t("userAccount.formInfo.country.placeholder")}
                  />
                  <div className="invalid-feedback">
                    {this.state.errors.country}
                  </div>
                </div>
                <div className="row">
                  <input
                      placeholder={t(
                          "userAccount.formInfo.phoneNumber.placeholder"
                      )}
                      type="tel"
                      onChange={this.handleChange}
                      value={this.state.user.phoneNumber}
                      name="phoneNumber"
                  />
                </div>
                {!checkeBoxInfo.hasMembership ? (
                    <div className="row settings-row">
                      <label className="passive">
                        {t("userAccount.sponserdButtonText")}
                      </label>
                      <div className="invalid-feedback">
                        {t("userAccount.sponserdButtonDescription")}
                      </div>
                    </div>
                ) : (
                    <div className="row settings-row">
                      <input
                          id="agree"
                          defaultChecked={this.state.checkeBoxStatus}
                          onClick={this.handleCheckeBox}
                          type="checkbox"
                          className="css-checkbox"
                      />
                      <label htmlFor="agree" className="css-label">
                        {t("userAccount.sponserdButtonText")}
                      </label>
                      <div className="invalid-feedback">
                        {this.state.checkeBoxErrorMessage}
                      </div>
                    </div>
                )}
                <div className="bottom row">
                  {this.state.successEdited ? (
                      <button className="updated">
                        {this.state.editSuccessMessage}
                      </button>
                  ) : (
                      <button onClick={this.editUser}>
                        {t("userAccount.submitButtonText")}
                      </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
    );
  }
}

UserEdit.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(UserEdit);
