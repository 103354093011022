import React, { Component } from "react";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";
import { authHeader } from "../../_helpers";
import axios from "axios";
import RelatedPost from "./relatedPosts";
import PostCountFunc from "./postCountFunc";
import AdvertisementBlock from "../userDashbord/advertisement/advertisementBlock";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import Footer from "../footerComponent/footer";

import PropTypes from "prop-types";

// Translation Higher Order Component
import { translate } from "react-switch-lang";
import {
  FacebookShareButton,
  //GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  //TelegramShareButton,
  WhatsappShareButton,
  //PinterestShareButton,
  VKShareButton,
  //OKShareButton,
  //RedditShareButton,
  //TumblrShareButton,
  //LivejournalShareButton,
  //MailruShareButton,
  //ViberShareButton,
  //WorkplaceShareButton,
  //LineShareButton,
  EmailShareButton
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  //TelegramIcon,
  WhatsappIcon,
  //GooglePlusIcon,
  LinkedinIcon,
  //PinterestIcon,
  VKIcon,
  //OKIcon,
  //RedditIcon,
  //TumblrIcon,
  //LivejournalIcon,
  //MailruIcon,
  //ViberIcon,
  //WorkplaceIcon,
  //LineIcon,
  EmailIcon
} from "react-share";
import MetaTags from "../metaTags";

const apiUrl = process.env.REACT_APP_API;
const imageUrl = process.env.REACT_APP_IMAGE_URL;


class PostSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: [],
      errorMessage: "",
      reportedItemValue: "",
      showSigninModal: false,
      thankYou: false,
      advertisementBlock: {},
      redirect: false,
      redirectUrl: ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleSigninModal = this.handleSigninModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleReportPost = this.handleReportPost.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  _isMounted = false;

  handleChange(e) {
    var value = e.target.value;

    this.setState({ reportedItemValue: value, errorMessage: "" });
  }

  componentDidMount() {
    let currentComponent = this;
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    // window.addEventListener('scroll', this.handleScroll);
    axios
        .get(
            apiUrl +
            "/posts/getbyurlkey/" +
            this.props.match.params.id +
            "?content=true",
            {
              headers: { lang: languageCode }
            }
        )
        .then(function(response) {
          const posts = response.data;
          currentComponent._isMounted = true;
          if (posts.post === undefined) {
            currentComponent.setState({
              redirect: true,
              redirectUrl: "/" + languageCode + "/not-found"
            });
          } else {
            currentComponent.setState({ post: posts });
          }
        })
        .catch(function(error) {
          if (error) {
            currentComponent._isMounted = true;
            let language = JSON.parse(localStorage.getItem("lang"));
            let languageCode = language.code;
            currentComponent.setState({
              redirect: true,
              redirectUrl: "/" + languageCode + "/not-found"
            });
          }
        });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleOpenModal(e) {
    this.setState({ showModal: true, thankYou: false });
  }
  handleSigninModal(e) {
    this.setState({ showSigninModal: true });
  }

  handleCloseModal() {
    const currentComponent = this;
    setTimeout(function() {
      currentComponent.setState({
        showModal: false,
        showSigninModal: false,
        reportedItemValue: ""
      });
    }, 100);
  }
  handleReportPost() {
    const value = this.state.reportedItemValue;
    if (value !== undefined && value !== "") {
      const curentUser = JSON.parse(localStorage.getItem("user"));
      const userId = curentUser._id;
      const postId = this.state.post.post.id;
      let postdata = {
        post: postId,
        user: userId,
        reason: value
      };
      const currentComponent = this;
      axios
          .post(apiUrl + "/reports/insert", JSON.stringify(postdata), {
            headers: authHeader()
          })
          .then(function(response) {
            if (response.data.code === 200) {
              currentComponent.setState({ thankYou: true });
            }
          });
    } else {
      this.setState({
        errorMessage: "Խնդրում ենք ընտրել տարբերակներից մեկը"
      });
    }
  }

  replaceHtmlEntites(text) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = { nbsp: " ", amp: "&", quot: '"', lt: "<", gt: ">" };
    return text.replace(translate_re, function(match, entity) {
      return translate[entity];
    });
  }

  render() {
    if (this._isMounted) {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirectUrl} />;
      }
      var post = this.state.post.post;
      // console.log(post)
      var selectedItems = this.state.post.selectedItems;
      var shareUrl = window.location.href;
      var curentUser = JSON.parse(localStorage.getItem("user"));
    } else {
      return false;
    }
    const { t } = this.props;
    // console.log(post)
    let createdAt = "";
    if (post.publishedDate !== null || post.publishedDate !== undefined) {
      createdAt = post.publishedDate;
    } else {
      createdAt = post.createdAt;
    }
    const currentComponent = this;
    if (this.state.thankYou === true) {
      window.setTimeout(function() {
        currentComponent.handleCloseModal();
      }, 3000);
    }
    let language = JSON.parse(localStorage.getItem("lang"));
    let languageCode = language.code;
    return (
        <div>
          <MetaTags />
          <Helmet>
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.title} />
            <meta property="og:image" content={imageUrl + post.thumbnail} />
          </Helmet>
          <ReactModal
              isOpen={this.state.showSigninModal}
              contentLabel="onRequestClose Example"
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="ReactModalPortal sign-in"
          >
            <div className="title">
              {t("postInner.loginPopUpInfo.popUpTitle")}
            </div>
            <Link className="button" to={"/" + languageCode + "/sing-in"}>
              {t("postInner.loginPopUpInfo.popUpLoginButtonText")}
            </Link>
            <button onClick={this.handleCloseModal}>
              {t("postInner.loginPopUpInfo.popUpCancelButtonText")}
            </button>
          </ReactModal>
          <ReactModal
              isOpen={this.state.showModal}
              contentLabel="onRequestClose Example"
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="ReactModalPortal report-post"
          >
            {this.state.thankYou ? (
                <div className="title">
                  {t("postInner.reportPopUpInfo.popUpThankyouTitle")}
                </div>
            ) : (
                <div>
                  <div className="title">
                    {t("postInner.reportPopUpInfo.popUpTitle")}
                  </div>
                  <div className="description">
                    {t("postInner.reportPopUpInfo.popUpDescription")}
                  </div>
                  <div className="report-items-list">
                    <div className="item">
                      <input
                          type="radio"
                          value="spam"
                          className="css-checkbox"
                          name="reportItem"
                          onChange={this.handleChange}
                          id="radio_1"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_1">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption1"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="insult"
                          name="reportItem"
                          onChange={this.handleChange}
                          id="radio_2"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_2">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption2"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="adultContent"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_3"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_3">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption3"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="drugAdvocacy"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_4"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_4">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption4"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="childPornography"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_5"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_5">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption5"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="extermism"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_6"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_6">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption6"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="violence"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_7"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_7">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption7"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="encouragingSuicide"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_8"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_8">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption8"
                        )}
                      </label>
                    </div>
                    <div className="item">
                      <input
                          type="radio"
                          className="css-checkbox"
                          value="other"
                          onChange={this.handleChange}
                          name="reportItem"
                          id="radio_9"
                      />
                      <label className="css-label radGroup1" htmlFor="radio_9">
                        {t(
                            "postInner.reportPopUpInfo.popUpReportInfo.reportOption9"
                        )}
                      </label>
                    </div>
                    <div className="invalid-feedback">
                      {this.state.errorMessage}
                    </div>
                  </div>
                  <button onClick={this.handleReportPost}>
                    {t("postInner.reportPopUpInfo.popUpSubmitButtonText")}
                  </button>
                  <button onClick={this.handleCloseModal}>
                    {t("postInner.reportPopUpInfo.popUpCancelButtonText")}
                  </button>
                </div>
            )}
          </ReactModal>
          <div className="post-inner-container">
            <div className="post-wrapper">
              <div className="post-container">
                <div className="top-section">
                  <div className="category-name">{post.category.title}</div>
                  <h1 className="title">{post.title}</h1>
                  <div className="wrap clear">
                    {post.user !== undefined && post.user !== null ? (
                        <div className="autor">
                          <Link to={"/" + languageCode + "/user/" + post.user.id}>
                            {post.user.firstName + " " + post.user.lastName}
                          </Link>
                        </div>
                    ) : (
                        ""
                    )}
                    {post.user !== undefined && post.user !== null ? (
                        <div className="divider"></div>
                    ) : (
                        ""
                    )}
                    <div className="date">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 299.99 299.99"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0Zm64.85,178.52H151.25c-.21,0-.41-.05-.63-.06s-.41.06-.63.06a10.37,10.37,0,0,1-10.37-10.37V62.25a10.38,10.38,0,0,1,20.75,0v95.53h54.47a10.37,10.37,0,0,1,0,20.74Z" />
                          </g>
                        </g>
                      </svg>
                      <Moment format="DD.MM.YYYY" date={createdAt} />
                    </div>
                    {/*post.isSponsored ? (
                    ""
                  ) : (
                    <PostCountFunc viewCount={post.views} />
                  )*/}
                  </div>
                  {/* <img alt="" src={imageUrl+post.thumbnail}/> */}
                  <div className="empty-block"></div>
                </div>
                <div className="post-content">
                  {selectedItems.map((list, key) => {
                    var content = "";
                    if (list.type === "Text") {
                      content = this.replaceHtmlEntites(list.value);
                    } else if (list.type === "Photo") {
                      content = "<img src=" + imageUrl + list.value + ' alt=""/>';
                    } else if (list.type === "Video") {
                      content =
                          '<iframe width="420" height="315" src=' +
                          list.value +
                          ' frameborder="0" allowfullscreen></iframe>';
                    }
                    return (
                        <div
                            key={key}
                            dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                    );
                  })}
                  <div className="share-report-box clear">
                    <div className="left">
                      <div className="text">{t("postInner.shareButtonText")}</div>
                      <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={24} round={false} />
                      </FacebookShareButton>
                      <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={24} round={false} />
                      </WhatsappShareButton>
                      <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={24} round={false} />
                      </LinkedinShareButton>
                      <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={24} round={false} />
                      </TwitterShareButton>
                      <VKShareButton url={shareUrl}>
                        <VKIcon size={24} round={false} />
                      </VKShareButton>
                      <EmailShareButton url={shareUrl}>
                        <EmailIcon size={24} round={false} />
                      </EmailShareButton>
                    </div>
                    <div className="right">
                      <div
                          className="report-button"
                          onClick={
                            curentUser
                                ? this.handleOpenModal
                                : this.handleSigninModal
                          }
                          id={post.id}
                      >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 461.55 459"
                        >
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Capa_1" data-name="Capa 1">
                              <g id="report">
                                <path d="M311.94,0H147.22a29.14,29.14,0,0,0-20.61,8.54L8.54,126.61A29.14,29.14,0,0,0,0,147.22v167A29.17,29.17,0,0,0,8.73,335L126.65,450.66A29.13,29.13,0,0,0,147.06,459H314.33a29.14,29.14,0,0,0,20.61-8.54L453,332.39a29.14,29.14,0,0,0,8.54-20.61V147.38a29.17,29.17,0,0,0-8.73-20.8L332.35,8.34A29.18,29.18,0,0,0,311.94,0ZM229.5,364.65c-17.85,0-33.15-15.3-33.15-33.15s15.3-33.15,33.15-33.15,33.15,15.3,33.15,33.15S247.35,364.65,229.5,364.65ZM255,255H204V102h51Z" />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <span>{t("postInner.reportButtonText")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="commercial-box-container">
                <div className="commercial-box">
                  <AdvertisementBlock type="post-inner" />
                </div>
              </div>
            </div>

            <div className="related-post-container" id="related-post-container">
              <div className="wrapper">
                <RelatedPost id={post.id} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
    );
  }
}

PostSingle.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate(PostSingle);
