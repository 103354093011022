import React, { Component } from 'react';
import axios from 'axios';
import validator from 'validator';
import FormValidator from '../validations';
import {
    Link
} from 'react-router-dom';
import MetaTags from '../metaTags'
import Footer from '../footerComponent/footer'
import PropTypes from 'prop-types';

// Translation Higher Order Component
import {
    translate,
} from 'react-switch-lang';

const apiUrl = process.env.REACT_APP_API;


class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        // const type=props.type;
        this.state = {
            user:{
                email:'',
            },
            messageFromServer: '',
            messageColor:'success',
            errors: [],
            errorClasses: [],
            resetSuccess:false,
        }
        this.handleChange = this.handleChange.bind(this);
        // this.onDismiss = this.onDismiss.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }
    _isMounted = false;

    componentDidMount(){
        // let currentComponent = this;


    }

    handleValidation(){
        // let fields = this.state.mail;
        const { t } = this.props;

        let errors = {};
        let errorClasses = {};
        let formIsValid = true;
        const validationRules = [
            {
                field: 'email',
                method: validator.isEmpty,
                validWhen: false,
                message: t('forgotPage.formInfo.email.errorMessage')
            },
            {
                field: 'email',
                method: validator.isEmail,
                validWhen: true,
                message: t('forgotPage.formInfo.email.errorMessageEmail')
            }

        ];

        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(this.state.user);
        if(!validation.isValid){
            formIsValid = false;
            for (var variable in validation) {
                if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
                    if(validation[variable]['isInvalid']){
                        errors[variable] = validation[variable]['message'];
                        errorClasses[variable] = "is-invalid";
                    }
                }
            }

        }
        this.setState({errors: errors,errorClasses:errorClasses});
        return formIsValid;
    }
    handleChange(e){
        const name =  e.target.name;
        const user = this.state.user;
        user[name] = e.target.value;
        this.setState({
            user
        });
        // console.log(this.state.user);
        //this.handleValidation();
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    sendEmail(e) {
        let currentComponent = this;
        currentComponent.setState({
            messageFromServer: ''
        });
        if(this.handleValidation()){
            let requestUrl = '/users/forgotpassword';
            let postdata = {
                email: this.state.user.email
            };

            axios.post(apiUrl+requestUrl,JSON.stringify(postdata),{
                headers:{'lang' : this.props.match.params.lang}
            })
                .then(function(response) {
                    if(response.data.code === 200){
                        currentComponent.setState({
                            resetSuccess: true
                        });
                    } else {
                        currentComponent.setState({
                            messageFromServer: response.data.error.message
                        });
                    }
                });
        }
    }
    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            let currentComponent=this;
            currentComponent.sendEmail()
        }
    }
    render() {
        const { t } = this.props;
        let language = JSON.parse(localStorage.getItem('lang'))
        let languageCode = language.code
        return (
            <div>
                <div className="forgot-password-container">
                    <MetaTags/>
                    <div className="table">
                        {this.state.resetSuccess ?
                            <div className="cell">
                                <div className="section-title no-margin">
                                    {t('forgotPage.thankyouTitle')}
                                </div>
                                <div className="section-description">
                                    {t('forgotPage.thankyouDescription')}
                                </div>
                                <Link className="button" to={`/${languageCode}/`}>{t('forgotPage.backButtonText')}</Link>
                            </div>
                            :
                            <div className="cell">
                                <h1 className="no-margin">{t('forgotPage.pageTitle')}</h1>
                                <div className="section-description">
                                    {t('forgotPage.pageDescription')}
                                </div>
                                <div className="wrap-box">
                                    <div className="row">
                                        <input tabIndex='1' onKeyPress={this.handleKeyPress} type="email" placeholder={t('forgotPage.formInfo.email.placeholder')} name="email" className={this.state.errorClasses.email}  onChange={this.handleChange} />
                                        <div className="invalid-feedback">{this.state.errors.email}{this.state.messageFromServer}</div>
                                    </div>
                                    <div className="bottom row">
                                        <button type="submit" tabIndex='2' onClick={this.sendEmail}>{t('forgotPage.submitButtonText')}</button>
                                        <div className="info">
                                            {t('forgotPage.registerText')} <Link to={`/${languageCode}/sign-up`}>{t('forgotPage.registorButtonText')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(ForgotPassword);